<script>
// import { localStorageHasTokens, setToken } from '@service/TokenService'
import { loginDirect } from '@service/ProfileService'

export default {
  async created() {
    // If logged in => redirect to page
    // const loggedIn = localStorageHasTokens()
    // if (loggedIn) this.$router.push({ name: 'Home' })
    //else save it to localstorage to navigate later
    // else {
    // Save items in store to use it after login or signup
    if ([...Object.keys(this.$route.query)].length > 0) {
      if (this.$route.query?.token) {
        let tokens = null
        try {
          const { data } = await loginDirect(this.$route.query.token)
          tokens = data
        } catch (ex) {
          console.log(ex)
        }
        if (tokens) {
          this.$store.dispatch('loginUser', tokens)
          const { completed } = tokens
          if (completed) this.$router.push({ name: 'Home' })
          else this.$router.push({ name: 'Signup' })
        } else {
          this.$router.push({ name: 'Login' })
        }
      } else {
        this.$router.push({ name: 'Login' })
      }
      // }
    } else {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
